import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import { PageContent } from "../templates/blog-post"
import React from "react"
import { css } from "@emotion/core"
/** @jsx jsx */
import { jsx } from "theme-ui"

function TriptychImage({ images, page }) {
  console.log(page)
  return (
    <Layout>
      <div
        css={css`
          max-width: 1000px;
        `}
      >
        <PageContent page={page} />
        <>
          {images.map(image => (
            <div
              sx={{
                mb: 6,
              }}
            >
              <img
                key={image.title}
                src={image.url}
                alt="Triptych"
                width="100%"
              />
              <div
                sx={{
                  fontWeight: "body",
                  fontSize: 0,
                  color: "gray6",
                  textAlign: "right",
                }}
              >
                {image.credit}
              </div>
            </div>
          ))}
        </>
      </div>
    </Layout>
  )
}

export default function () {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark {
            nodes {
              id
              frontmatter {
                title
                image
                credit
              }
              fields {
                slug
              }
            }
          }
          markdownRemark(fields: { slug: { eq: "/page/gallery/" } }) {
            html
            frontmatter {
              title
              vimeo
              youtube
            }
          }
        }
      `}
      render={data => {
        const images = data.allMarkdownRemark.nodes
          .filter(node => {
            const folder = node.fields.slug.split("/")[1]
            return folder === "triptych"
          })
          .sort((nodeA, nodeB) =>
            nodeA.fields.slug < nodeB.fields.slug ? -1 : 1
          )
          .map(node => ({
            url: node.frontmatter.image,
            credit: node.frontmatter.credit,
            slug: node.fields.slug,
          }))

        return <TriptychImage images={images} page={data.markdownRemark} />
      }}
    />
  )
}
